import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import Main from './components/Main/Main';
import Proofs from './components/Main/Proofer/Proofs/Proofs';
import GenerateProof from './components/Main/Proofer/GenerateProof/GenerateProof';
import useToken from './components/Hooks/useToken';
import useUser from './components/Hooks/useUser';
import Services from './components/Services/Services';
import Proofer from './components/Main/Proofer/Proofer';
import SetPassword from './components/SetPassword/SetPassword';
import OperationDashboards from './components/Main/OperationDashboards/OperationDashboards';
import Dashboards from './components/Main/OperationDashboards/Dashboards/Dashboards';
import AccountRecs from './components/Main/OperationDashboards/AccountRecs/AccountRecs';
import TagChecker from './components/Main/TagChecker/TagChecker';
import Clients from './components/Main/TagChecker/Clients/Clients';
import Check from './components/Main/TagChecker/Check/Check';
import SFTPUserManager from './components/Main/SFTPUserManager/SFTPUserManager';
import OperationDashboardsAdmin from './components/Main/OperationDashboardsAdmin/OperationDashboardsAdmin';
import CampaignGrouper from './components/Main/OperationDashboardsAdmin/CampaignGrouper/CampaignGrouper';
import CampaignToClientMapper from './components/Main/OperationDashboardsAdmin/CampaignToClientMapper/CampaignToClientMapper';
import OneOffRecords from './components/Main/OperationDashboardsAdmin/OneOffRecords/OneOffRecords';
import BackendDataWindow from './components/Main/OperationDashboardsAdmin/BackEndDataWindow/BackendDataWindow';
import ForceRefreshDatasets from './components/Main/OperationDashboardsAdmin/ForceRefreshDatasets/ForceRefreshDatasets';
import CampaignPriceList from './components/Main/OperationDashboardsAdmin/CampaignPriceList/CampaignPriceList';
import CampaignVolumeCapList from './components/Main/OperationDashboardsAdmin/CampaignVolumeCapList/CampaignVolumeCapList';
import InvoiceCountDown from './components/Main/OperationDashboardsAdmin/InvoiceCountDown/InvoiceCountDown';
import OptOut from './components/Main/OptOut/OptOut';
import ShopifyAPIAccess from './components/Main/ShopifyAPIAccess/ShopifyAPIAccess';
import List from './components/Main/OptOut/List/List';
import ShopifyForm from './components/Main/ShopifyAPIAccess/ShopifyForm/ShopifyForm';
import ShopifyAPICheck from './components/Main/ShopifyAPIAccess/ShopifyAPICheck/ShopifyAPICheck';

function App() {
  const {token, setToken} = useToken(null);

  const {user, setUser} = useUser(null);

  if(!token){
    return (
      <div className="App">
        <BrowserRouter>
        <Routes>
          <Route path="/set-password" element={<SetPassword setToken={setToken} setUser={setUser} />} />
          <Route path="/*" element={<Login setToken={setToken} setUser={setUser} />} />
        </Routes>
        </BrowserRouter>
      </div>
    );
  }

  console.log(`Environment: ${process.env.REACT_APP_ENV}`);

  return (
    <div className="App">
      <Outlet />
      <BrowserRouter>
      <Routes>
        <Route path="/services" element={<Services />} />
        <Route path="/" element={<Main />}>
          <Route path="" element={<Navigate replace to="/services" />} />
          {user.groups.includes('ProoferGroup') &&
          <Route path="proofer" element={<Proofer />}>
            <Route path="proofs" element={<Proofs />} />
            <Route path="generate" element={<GenerateProof userToken={token} />} />
            <Route path="" element={<Navigate replace to="/proofer/proofs" />} />
          </Route>
          }
          {user.groups.includes('ProoferGroup') &&
          <Route path="operation-dashboards" element={<OperationDashboards />}/>
          }
          {user.groups.includes('TagCheckerGroup') &&
          <Route path="tag-checker" element={<TagChecker />}>
            <Route path="clients" element={<Clients />} />
            <Route path='check'>
              <Route path="" element={<Check userToken={token} />} />
              <Route path=":id/:ref" element={<Check userToken={token} />} />
            </Route>
            <Route path="" element={<Navigate replace to="/tag-checker/clients" />} />
          </Route>
          }
          {user.groups.includes('SFTPAdminGroup') &&
          <Route path="create-sftp-user" element={<SFTPUserManager />}/>
          }
          {user.groups.includes('OperationsDashboardsAdminGroup') &&
          <Route path="operations-dashboard-admin" element={<OperationDashboardsAdmin />}>
            <Route path="camp-grouper" element={<CampaignGrouper />} />
            <Route path="camp-to-client-mapper" element={<CampaignToClientMapper/>} />
            <Route path="one-off-print-record" element={<OneOffRecords/>} />
            <Route path="back-end-data-window" element={<BackendDataWindow/>} />
            <Route path="force-refresh-datasets" element={<ForceRefreshDatasets/>} />
            <Route path="campaign-price-list" element={<CampaignPriceList/>} />
            <Route path="campaign-volume-cap-list" element={<CampaignVolumeCapList/>} />
            <Route path="invoice-countdown" element={<InvoiceCountDown/>} />
            <Route path="" element={<Navigate replace to="/operations-dashboard-admin/one-off-print-record" />} />
          </Route>
          }
          {user.groups.includes('OptOutGroup') &&
          <Route path="opt-out" element={<OptOut />}>
            <Route path="list" element={<List />} />
            <Route path="" element={<Navigate replace to="/opt-out/list" />} />
          </Route>
          }
          {user.groups.includes('ShopifyApiGroup') && 
          <Route path="test-shopify-access" element={<ShopifyAPIAccess />}>
            <Route path="form"  element={<ShopifyForm />}/>
            <Route path='check'>
              <Route path="" element={<ShopifyAPICheck />}/>
              <Route path=":domain" element={<ShopifyAPICheck />} />
            </Route>
            <Route path="" element={<Navigate replace to="/test-shopify-access/form" />} />
          </Route>
          }
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
