import React from 'react';
import './TagChecker.css';
import SideBar from './SideBar/SideBar';
import { Outlet } from 'react-router-dom';

export default function TagChecker(props) {
    return (
        <div className="proofer">
            <SideBar />
            <Outlet />
        </div>
    );
}