import React from 'react';
import './Tags.css';
import AbandonedBaskets from './AbandonedBaskets/AbandonedBaskets';
import SoldBaskets from './SoldBaskets/SoldBaskets';
import AbandonedProducts from './AbandonedProducts/AbandonedProducts';
import SoldProducts from './SoldProducts/SoldProducts';
import UserId from './UserId/UserId';
import NamesAddresses from './NamesAddresses/NamesAddresses';

export default function Tags({tag, setDetails}){
    switch(tag?.tagName){
        case 'abandoned_baskets':
            return <AbandonedBaskets data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'sold_baskets':
           return <SoldBaskets data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'abandoned_products':
            return <AbandonedProducts data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'sold_products':  
            return <SoldProducts data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'user_ids':  
            return <UserId data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'names_addresses':  
            return <NamesAddresses data={tag.data} number={tag.number} setDetails={setDetails} />
        default:
            return <div></div>
    }
}