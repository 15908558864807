import React, { useState } from 'react';
import './SFTPUserManager.css';
import SideBar from './SideBar/SideBar';
import { Outlet } from 'react-router-dom';
import api_config from '../../../api_config.json';
import { ThreeDots } from 'react-loader-spinner';
import useToken from '../../Hooks/useToken';

export default function SFTPUserManager(props) {
    const {token} = useToken();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [publicKey, setPublicKey] = useState('');
    const [usePassword, setUsePassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e, setter) => setter(e.target.value);

    const isValidPublicKey = (pubKey) => {
        const keyParts = pubKey.split(' ');
        const keyType = keyParts[0];
        return keyType === 'ssh-rsa' || keyType === 'ssh-dss' || keyType === 'ecdsa-sha2-nistp256' || keyType === 'ecdsa-sha2-nistp384' || keyType === 'ecdsa-sha2-nistp521' || keyType === 'ssh-ed25519';
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleCreateNewUser(username, usePassword ? password : '', usePassword ? '' : publicKey);
    }

    function handleCreateNewUser(username, password, pubkey) {
        if(password == '' && !isValidPublicKey(pubkey)){
            alert("The public key format is not valid please go back and fix it. Must start with \"ssh-rsa\" or other valid key type")
            return;
        }

        setIsLoading(true);
        fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/sftp/create', {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: JSON.stringify({
                username: username,
                password: password,
                publicKey: pubkey
            })
        })
        .then(response => {
            if (response.status === 200) {
                alert("User created successfully");
                return response.json();
            } else {
                throw new Error("Error " + response.status + ": " + response.statusText);
            }
        })
        .then(dataIn => {
            console.log(dataIn);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            alert("Error: " + error.message);
            setIsLoading(false);
        });
    }

    return (
        <div className="proofer">
            <SideBar />
            <div >
                <form className="sftpUserForm" onSubmit={handleSubmit}>
                    <label style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"}}>
                        <h5>Username : </h5>
                        <input type="text" value={username} onChange={(e) => handleInputChange(e, setUsername)} required/>
                    </label>
                    <label style={{display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", width: "100%"}}>
                        <h5>Use SSH Auth : </h5>
                        <input className="checkBox" type="checkbox" checked={!usePassword} onChange={(e) => setUsePassword(!e.target.checked)}/> 
                    </label>
                    <label style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"}}>
                    {usePassword && <h5>Password : </h5> } {!usePassword && <h5>PublicKey : </h5>}
                        <input type={usePassword ? "password" : "text"} value={usePassword ? password : publicKey} onChange={(e) => {
                            const inputValue = e.target.value;
                            handleInputChange(e, usePassword ? setPassword : setPublicKey);
                            if (usePassword && isValidPublicKey(inputValue)) {
                                alert("Warning: This looks like an SSH Public Key. Switch to SSH Auth if you want to use it.");
                            }
                        }} required/>
                    </label>
                    <button type="submit" disabled={isLoading}> {!isLoading ? 'Create User' : 
                    <ThreeDots 
                    height="40" 
                    width="60" 
                    radius="4"
                    color="#FFFFFF" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                    />
                    }      
                    </button>
                </form>
            </div>
        </div>
    );
}
