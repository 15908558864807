import React, { useEffect, useState, useRef } from 'react';
import './CampaignPriceList.css';
import useToken from '../../../Hooks/useToken';
import Spreadsheet from "react-spreadsheet";
import api_config from '../../../../api_config.json';
import { ThreeDots } from 'react-loader-spinner';

export default function CampaignPriceList(props) {
    const [data, setData] = useState([]);
    const {token} = useToken();
    const [isLoading, setIsLoading] = useState(true);
    const fileInput = useRef(); // To manage the file input for upload
    const [bucketName, setBucketName] = useState("accountrecautomation-camptopricebucket-1eep57b84ak4l"); //hacked this together based of prvious code for quick deliver change later so avoid using varibale for constatn bucket name ie remove this const
    const [listOfFileNames, setListOfFileNames] = useState([]);
    const [fileName, setFileName] = useState("");



    useEffect(() => {
        fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/admin/getObjectList', {
            method: 'POST',
            body : JSON.stringify({bucket: bucketName }),
            headers: {
                'Authorization': token
            }
        })
        .then(response => response.json())
        .then(dataIn => {
            console.log(dataIn)
            const list = dataIn.objectKeys;
            // Custom sort function for file names
            list.sort((a, b) => {
                // Extract year and month from file names
                let dateA = a.match(/_(\d{2})_(\d{4})\.csv$/);
                let dateB = b.match(/_(\d{2})_(\d{4})\.csv$/);
    
                if(dateA && dateB) {
                    // Parse year and month as integers
                    let yearA = parseInt(dateA[2], 10);
                    let monthA = parseInt(dateA[1], 10);
                    let yearB = parseInt(dateB[2], 10);
                    let monthB = parseInt(dateB[1], 10);
    
                    // Compare years, if years are same then compare months
                    if(yearA !== yearB) {
                        return yearA - yearB;
                    } else {
                        return monthA - monthB;
                    }
                }
                return 0; // In case of files not following the expected format, consider them equal
            });
    
            setListOfFileNames(list)
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [bucketName]);
    


 
    

    const parseCsv = (csvContent) => {
        const lines = csvContent.split('\n');
        const headers = lines[0].split(',');
        headers.forEach((header, index) => {
            header = header.replace("\r", "");
        });

        const data = [];
        for (let i = 0; i < lines.length-1; i++) {
            const row = [];
            const currentline = lines[i].split(',')
            for (let j = 0; j < headers.length; j++) {
                row.push({ value: currentline[j] });
            }
            data.push(row);
        }

        return data;
    };

    const convertToCsv = (data) => {
        let csvContent = "";
        data.forEach(row => {
            csvContent += row.map(cell => cell.value).join(",") + "\n";
        });
        return csvContent;
    };

    const handleSave = () => {
        if (window.confirm("Are you sure you want to save this?")) {
            console.log(data);
            // if(data[0] != [{"value": "Campaign"},{"value": "QTY"},{"value": "Date\r"}]){
            //     alert("The header row is invalid must be : Campaign, QTY, Date");
            //     return;
            // }
            const csvContent = convertToCsv(data);
    
            
    
            fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/admin/saveCSV', {
                method: 'POST',
                body : JSON.stringify({ csvFile: csvContent, fileKey: fileName, bucket: bucketName}),
                headers: {
                    'Authorization': token
                }
            })
            .then(response => {
                alert("Saved successfully. If you do not see the changes you have saved please refresh the page");
            })
            .catch(error => {
                console.log(error);
                alert("Error saving file.");
                setIsLoading(false);
            });
        }
    };
    
    const addRow = () => {
        const newRow = new Array(data[0].length).fill({ value: "" });
        setData([...data, newRow]);
    };

    const deleteRow = () => {
        const rowNum = prompt("Enter row number to delete:");
        const parsedRowNum = parseInt(rowNum, 10);
    
        if (isNaN(parsedRowNum) || parsedRowNum < 2   || parsedRowNum >= data.length+2) {
            alert("Invalid row number. Please enter a valid row number.");
            return;
        }
    
        const newData = data.filter((_, index) => index !== (parsedRowNum-1));
        setData(newData);
    };
    

    const handleDownload = () => {
        const csvContent = convertToCsv(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "download.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function(event) {
            const csvData = parseCsv(event.target.result);
            setData(csvData);
        };
        reader.readAsText(file);
    };



    const setFile = async (fileName) => {
        
        try {
            setIsLoading(true);
            
            const response = await fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/admin/getCSV', {
                method: 'POST',
                body : JSON.stringify({ fileKey: fileName, bucket: bucketName }),
                headers: {
                    'Authorization': token
                }
            })
            const data = await response.json();
            const csvContent = data.csvContent;
            const csvData = parseCsv(csvContent);
            setData(csvData);
            setIsLoading(false);
            setFileName(fileName);
        } catch(error) {
            console.log(error);
            setIsLoading(false);
        }
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'stretch', alignItems: 'center', height: '94vh'}}>
            {fileName == "" &&
            <>
                <h1>Choose CSV File To View/Edit</h1>
                {listOfFileNames && listOfFileNames.length > 0 ?
                <div className="file-selector">
                    {isLoading &&
                        <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />
                    }
                    <ul style={{listStyleType: "none"}}>
                        {listOfFileNames.map((file, index) => (
                            <li className="file-selector-element" key={index} onClick={() => setFile(file) }> {file} </li>
                        ))}
                    </ul>
                </div>
                :
                <>
                    <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />
                </>
                }
            </>
            }
            {fileName != ""  &&
            <>
                <div className="back-to-bucket-selectors">
                    <button onClick={() => setFileName("")}>Back to file selectors</button>
                </div>
                {(!isLoading && Array.isArray(data) && data.length > 0) ?
                    <div className='spreadsheet-container'>
                        <div className='spreadsheet-controls'>
                        <button onClick={handleSave}>Save</button>
                        <button onClick={addRow}>Add Row</button>
                        <button onClick={deleteRow}>Delete Row</button>
                        <button onClick={handleDownload}>Download CSV</button>
                        <input type="file" accept=".csv" ref={fileInput} onChange={handleFileUpload} style={{display: "none"}}/>
                        <button onClick={() => fileInput.current.click()}>Upload CSV</button>
                        </div>
                        <p>Keep column F the same through out </p>
                        <div className='spreadsheet-content'>
                        <Spreadsheet data={data} onChange={setData} />
                        </div>
                    </div> :
                    <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />}
            </>
            }
        </div>
    );
}
