import api_config from '../../../../api_config.json';

const API_URL = api_config[process.env.REACT_APP_ENV].API_URL;

export const checkAbandonedBaskets = async (token, idSite, date, period) => {
    const url = `${API_URL}/tag-checker/carts/${idSite}/${period}/${date}?authToken=MATOMO_AUTH_TOKEN&abandoned=1`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                data: data,
                isLoading: false,
                isChecked: data.nb_conversions > 0 ? true : false,
                number: data.nb_conversions
            };
        } catch (error) {
            throw error;
        }
}

export const checkSoldBaskets = async (token, idSite, date, period) => {
    const url = `${API_URL}/tag-checker/carts/${idSite}/${period}/${date}?authToken=MATOMO_AUTH_TOKEN&abandoned=0`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                data: data,
                isLoading: false,
                isChecked: data.nb_conversions > 0 ? true : false,
                number: data.nb_conversions
            };
        } catch (error) {
            throw error;
        }
}

export const checkAbandonedProducts = async (token, idSite, date, period) => {
    const url = `${API_URL}/tag-checker/products/${idSite}/${period}/${date}?authToken=MATOMO_AUTH_TOKEN&abandoned=1`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                data: (data && data.length) > 0 ? data.slice(0, 5) : null,
                isLoading: false,
                isChecked: data.length > 0 ? true : false,
                number: data.length
            };
        } catch (error) {
            throw error;
        }
}

export const checkSoldProducts = async (token, idSite, date, period) => {
    const url = `${API_URL}/tag-checker/products/${idSite}/${period}/${date}?authToken=MATOMO_AUTH_TOKEN&abandoned=0`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                data: (data && data.length) > 0 ? data.slice(0, 5) : null ,
                isLoading: false,
                isChecked: data.length > 0 ? true : false,
                number: data.length
            };
        } catch (error) {
            throw error;
        }
}

export const checkUserIds = async (token, idSite, date, period) => {
    const url = `${API_URL}/tag-checker/user-ids/${idSite}/${period}/${date}?authToken=MATOMO_AUTH_TOKEN&abandoned=0`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                data: (data && data.length) > 0 ? data.slice(0, 5).map(e => ({...e, label: e.label.replace(/^[^@]+/, '*****')})) : null,
                isLoading: false,
                isChecked: data.length > 0 ? true : false,
                number: data.length
            };
        } catch (error) {
            throw error;
        }
}

export const checkNamesAddresses = async (token, idSite, date, period) => {
    const url = `${API_URL}/tag-checker/names-addresses/${idSite}/${period}/${date}?authToken=MATOMO_AUTH_TOKEN`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                data: data.data,
                isLoading: false,
                isChecked: data.number > 0 ? true : false,
                number: data.number
            };
        } catch (error) {
            throw error;
        }
}