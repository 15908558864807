import { useState } from "react";
import "./ShopifyForm.css";

export default function ShopifyForm() {
    const [domain, setDomain] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [key, setKey] = useState("");

    const handleInputChange = (e, setter) => setter(e.target.value);

    const testAPIs = async (e) =>{
        e.preventDefault();
        console.log(domain, accessToken, key);
        const formInputs = {
            shopifyAccessToken: accessToken,
            shopifyKey: key
        }
        sessionStorage.setItem("shopifyCredentials", JSON.stringify(formInputs))
        window.location.href = `/test-shopify-access/check/${domain}`
    }

    return (
    <div>
        <form className="shopifyTokenForm" onSubmit={testAPIs}>
           <label style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"}}>
                <h5>Domain</h5>
                <input type="text" value={domain} onChange={(e) => handleInputChange(e, setDomain)} required/>
           </label>
           <label style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"}}>
                <h5>API Access Token</h5>
                <input type="text" value={accessToken} onChange={(e) => handleInputChange(e, setAccessToken)} required/>
           </label>
           <label style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "100%"}}>
                <h5>API Key</h5>
                <input type="text" value={key} onChange={(e) => handleInputChange(e, setKey)} required/>
           </label>
           <button type="submit">Test APIs</button>
        </form>
    </div>
    )
}