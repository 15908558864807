import React, { useEffect, useState, useRef } from 'react';
import './CampaignGrouper.css';
import useToken from '../../../Hooks/useToken';
import Spreadsheet from "react-spreadsheet";
import api_config from '../../../../api_config.json';
import { ThreeDots } from 'react-loader-spinner';

export default function CampaignGrouper(props) {
    const [data, setData] = useState([]);
    const { token } = useToken();
    const [isLoading, setIsLoading] = useState(true);
    const fileInput = useRef(); // To manage the file input for upload


    useEffect(() => {
        fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/admin/getCSV', {
            method: 'POST',
            body : JSON.stringify({ fileKey: 'grouper.csv', bucket: api_config[process.env.REACT_APP_ENV].GrouperBucket }),
            headers: {
                'Authorization': token
            }
        })
        .then(response => response.json())
        .then(dataIn => {
            const csvContent = dataIn.csvContent;
            const csvData = parseCsv(csvContent);
            setData(csvData);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, []);

    const parseCsv = (csvContent) => {
        const lines = csvContent.split('\n');
        const headers = lines[0].split(',');
        headers.forEach((header, index) => {
            header = header.replace("\r", "");
        });

        const data = [];
        for (let i = 0; i < lines.length-1; i++) {
            const row = [];
            const currentline = lines[i].split(',')
            for (let j = 0; j < headers.length; j++) {
                row.push({ value: currentline[j] });
            }
            data.push(row);
        }

        return data;
    };

    const convertToCsv = (data) => {
        let csvContent = "";
        data.forEach(row => {
            csvContent += row.map(cell => cell.value).join(",") + "\n";
        });
        return csvContent;
    };

    const handleSave = () => {
        if (window.confirm("Are you sure you want to save this?")) {
            console.log(data);
            // if(data[0] != [{"value": "Campaign"},{"value": "QTY"},{"value": "Date\r"}]){
            //     alert("The header row is invalid must be : Campaign, QTY, Date");
            //     return;
            // }
            const csvContent = convertToCsv(data);
    
            
    
            fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/admin/saveCSV', {
                method: 'POST',
                body : JSON.stringify({ csvFile: csvContent, fileKey: 'grouper.csv', bucket: api_config[process.env.REACT_APP_ENV].GrouperBucket}),
                headers: {
                    'Authorization': token
                }
            })
            .then(response => {
                alert("Saved successfully. If you do not see the changes you have saved please refresh the page");
            })
            .catch(error => {
                console.log(error);
                alert("Error saving file.");
                setIsLoading(false);
            });
        }
    };
    

    const addRow = () => {
        const newRow = new Array(data[0].length).fill({ value: "" });
        setData([...data, newRow]);
    };

    const deleteRow = () => {
        const rowNum = prompt("Enter row number to delete:");
        const parsedRowNum = parseInt(rowNum, 10);
    
        if (isNaN(parsedRowNum) || parsedRowNum < 2   || parsedRowNum >= data.length+2) {
            alert("Invalid row number. Please enter a valid row number.");
            return;
        }
    
        const newData = data.filter((_, index) => index !== (parsedRowNum-1));
        setData(newData);
    };
    

    const handleDownload = () => {
        const csvContent = convertToCsv(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "download.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function(event) {
            const csvData = parseCsv(event.target.result);
            setData(csvData);
        };
        reader.readAsText(file);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'stretch', alignItems: 'center', height: '82vh'}}>
            <h1>Grouper</h1>
            
            {(!isLoading && Array.isArray(data) && data.length > 0) ?
                <div className='spreadsheet-container'>
                    <div className='spreadsheet-controls'>
                    <button onClick={handleSave}>Save</button>
                    <button onClick={addRow}>Add Row</button>
                    <button onClick={deleteRow}>Delete Row</button>
                    <button onClick={handleDownload}>Download CSV</button>
                    <input type="file" accept=".csv" ref={fileInput} onChange={handleFileUpload} style={{display: "none"}}/>
                    <button onClick={() => fileInput.current.click()}>Upload CSV</button>
                    </div>
                    <p style={{fontSize: '17px', textAlign: 'center'}}>Any Campaign Codes written to a "Old Name" column will appear as the Campaign Code written to the "Constant Campaign Name" column of the same row in the QuickSight DashBoards. </p>
                    <p style={{fontSize: '13px'}}>Note any data that was written to Quicksight before the creation of a grouping will need to be sent through the pipelines again to have the grouping applied</p>
                    <div className='spreadsheet-content'>
                        <Spreadsheet data={data} onChange={setData} />
                    </div>
                </div> :
                <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />}
        
        </div>
    );
}
