import React from 'react';
import './Services.css';
import Navbar from './Navbar/Navbar.js';
import Service from './Service/Service.js';
import prooferLogo from './assets/proof-reading.png';
import Container from 'react-bootstrap/Container';
import useUser from '../Hooks/useUser';
import opsDashboardLogo from './assets/ops-dashboard.png';
import tagChckerLogo from './assets/tag-checker.png';
import sftp from './assets/sftp.png';
import optOutLogo from './assets/opt-out.png';
import shopifyAPI from './assets/shopify.png'

export default function Services() {
    const {user} = useUser(null);
    return (
        <div className="services">
            <Navbar className='navbar'/>
            <Container className='services-container'>
                {user.groups.includes('ProoferGroup') &&
                    <Service img={prooferLogo} title='Proofer' link='/proofer'/>
                }
                {user.groups.includes('ProoferGroup') &&
                    <Service img={opsDashboardLogo} title='Operation Dashboards' link='/operation-dashboards'/>
                }
                {user.groups.includes('TagCheckerGroup') &&
                    <Service img={tagChckerLogo} title='Tag Checker' link='/tag-checker'/>
                }
                {user.groups.includes('SFTPAdminGroup') &&
                    <Service img={sftp} title='Create SFTP User' link='/create-sftp-user'/>
                }
                {user.groups.includes('OperationsDashboardsAdminGroup') &&
                    <Service img={opsDashboardLogo} title='Administration for Ops Dashboards' link='/operations-dashboard-admin'/>
                }
                {user.groups.includes('OptOutGroup') &&
                    <Service img={optOutLogo} title='Opt-out' link='/opt-out'/>
                }
                {user.groups.includes('ShopifyApiGroup') && 
                    <Service img={shopifyAPI} title='Test Shopify API' link='/test-shopify-access'/>
                }
            </Container>
        </div>
    );
}