import React from 'react';
import proofs_icon from './assets/proofs-icon.svg';
import generate_icon from './assets/generate-icon.svg';
import settings_icon from './assets/settings-icon.svg';
import back_icon from './assets/back.svg'; 
import './SideBar.css';
import logo from './assets/operations-logo.png';
import { Link, useLocation } from 'react-router-dom';

export default function TopBar(props) {
    const active = {backgroundColor: '#6359E9'};
    const location = useLocation();
    const path = location.pathname;
    
    return (
        <div className="side-bar">
            <div className="side-bar--logo">
                <img src={logo} alt="logo" />
                <h1>Operations</h1>
            </div>
            <div className="side-bar--menu">
                {props.dashboards && Object.keys(props.dashboards).map((key, index) => {
                    return (
                        <Link className="side-bar--menu-item" key={index} onClick={() => props.handleChangeDashboard(key)} style={key == props.currentDashboard ? active : {}}>
                            <img src={generate_icon} alt="generate-icon" />
                            <p>{key}</p> 
                        </Link>
                    );
                })}
                <Link className="side-bar--menu-item back" to='/services'>
                    <img src={back_icon} alt="settings-icon" />
                    <p>Back</p>
                </Link>
            </div>
        </div>
    );
}