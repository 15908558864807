import React from 'react';
import close_icon from '../assets/x.svg';

export default function Products(props){
    return (
        <div className='details-container' >
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            {props.data && props.data.length > 0 &&
            <>
                <div className='details-intro'>
                    <p>{props.number === 10000 ? 'More than 10,000' : props.number} products have been collected.</p>
                    <p><span style={{fontWeight: 'normal'}}>Here is a sample of {props.data.length} products:</span></p>
                </div>
                <table>
                <thead>
                    <tr>
                        <th>Product ID</th>
                        <th>Title</th>
                        <th>Product Image</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(e => <tr key={e.id}>
                        <td>{e.id}</td>
                        <td>{e.title}</td>
                        <td><img src={e.image?.src ?? ""} alt="Product" style={{height: "75px", width: "75px"}}/></td>
                    </tr>)}
                </tbody>
                </table>
            </>
            }
            {!props.number && 
                <p>Status {props.data.status} - {props.data.errors}</p>
            }
        </div>
    );
}