import React from 'react';
import proofs_icon from './assets/proofs-icon.svg';
import generate_icon from './assets/generate-icon.svg';
import settings_icon from './assets/settings-icon.svg';
import back_icon from './assets/back.svg'; 
import './SideBar.css';
import logo from './assets/operations-logo.png';
import { Link, useLocation } from 'react-router-dom';

export default function TopBar(props) {
    const active = {backgroundColor: '#6359E9'};
    const location = useLocation();
    const path = location.pathname;
    
    return (
        <div className="side-bar">
            <div className="side-bar--logo">
                <img src={logo} alt="logo" />
                <h1>Operations</h1>
            </div>
            <div className="side-bar--menu">
                <Link className="side-bar--menu-item" to="one-off-print-record" style={path.includes('one-off-print-record') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>One Off Records</p>
                </Link>
                <Link className="side-bar--menu-item" to="camp-to-client-mapper" style={path.includes('camp-to-client-mapper') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Campagin To Client Mapper</p>
                </Link>
                <Link className="side-bar--menu-item" to="camp-grouper" style={path.includes('camp-grouper') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Campagin Grouper</p>
                </Link>
                <Link className="side-bar--menu-item" to="back-end-data-window" style={path.includes('back-end-data-window') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Backend Data Window</p>
                </Link>
                <Link className="side-bar--menu-item" to="campaign-price-list" style={path.includes('campaign-price-list') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Campagin Price List</p>
                </Link>
                <Link className="side-bar--menu-item" to="invoice-countdown" style={path.includes('invoice-countdown') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Invoice Countdown</p>
                </Link>
                <Link className="side-bar--menu-item" to="campaign-volume-cap-list" style={path.includes('campaign-volume-cap-list') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Volume Caps</p>
                </Link>
                <Link className="side-bar--menu-item" to="force-refresh-datasets" style={path.includes('force-refresh-datasets') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Force Refresh Datasets</p>
                </Link>
                <Link className="side-bar--menu-item back" to='/services'>
                    <img src={back_icon} alt="settings-icon" />
                    <p>Back</p>
                </Link>
                
            </div>
        </div>
    );
}