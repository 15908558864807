import React from 'react';
import './ShopifyAPICheck.css';
import useToken from '../../../Hooks/useToken';
import Tag from './Tag/Tag';
import Tags from './Tags/Tags';
import {
    checkOrderAPIresponse, 
    checkCustomersAPIresponse, 
    checkProductsAPIresponse, 
} from './getData'
import refresh_icon from './assets/arrow-clockwise.svg';
import Swal from 'sweetalert2';
import { useParams, Link } from 'react-router-dom';

export default function ShopifyAPICheck() {
    const {token} = useToken()
    const { domain } = useParams();
    const [client] = React.useState({
        'shopify_domain': domain ? domain : null,
    });
    
    const [details, setDetails] = React.useState(null)
    const [tags, setTags] = React.useState([]);  
    const [refresh, setRefresh] = React.useState(false);
    const [refreshBtnActive, setRefreshBtnActive] = React.useState(false);
    const { shopifyAccessToken, shopifyKey } = JSON.parse(sessionStorage.getItem("shopifyCredentials")) ?? {}

    React.useEffect(() => {
        async function checkTags(){
            setRefreshBtnActive(false);
            if (domain){
                const ordersResponse = await checkOrderAPIresponse(token, domain, shopifyAccessToken, shopifyKey); 
                setTags(prev => ({...prev, orders_response: ordersResponse}));
                const customersResponse = await checkCustomersAPIresponse(token, domain, shopifyAccessToken, shopifyKey); 
                setTags(prev => ({...prev, customers_response: customersResponse}));
                const productsResponse = await checkProductsAPIresponse(token , domain, shopifyAccessToken, shopifyKey);
                setTags(prev => ({...prev, products_response: productsResponse}));
            }
            setRefreshBtnActive(true);
        }
        checkTags().catch(error => {
            Swal.fire({
              title: 'Error!',
              text: `The following error has occurred. Please contact your administrator. Error: ${error}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
            console.error(error);
        });
        
    }, [token, domain, client, refresh, shopifyAccessToken, shopifyKey])

    const handleRefresh = () => {
        setTags([]);
        setDetails(null);
        setRefresh(prev => !prev);
    }

    const handleSetDetails = (tag) => {
        if(tag === null || Object.keys(tags).includes(tag.tagName)){
            setDetails(tag);
        }
    }

    return (
        <div>
            <div className="shopify-checker--intro">
                {client.shopify_domain ?
                    <h1>Checking Shopify API endpoints - {client.shopify_domain}</h1>
                :
                    <h1>Check Shopify API endpoints</h1>
                }
            </div>
            <div className="shopify-checker">
                {!client.shopify_domain && 
                    <div className='initial-button-container'>
                        <Link to='/test-shopify-access/form'><button>Enter Shopify domain & credentials</button></Link>
                    </div>
                }
                {client.shopify_domain && <div className='tag-section'>
                    <div className='tags-list'>
                        <Tag tag={tags.orders_response} label='Fetching Orders API response' tagName='orders_response' setDetails={handleSetDetails} />
                        <Tag tag={tags.customers_response} label='Fetching Customers API response' tagName='customers_response' setDetails={handleSetDetails} />
                        <Tag tag={tags.products_response} label='Fetching Products API response' tagName='products_response' setDetails={handleSetDetails} />
                    </div>
                    <Tags tag={details} setDetails={handleSetDetails} />
                </div>}
            </div>
            {client.shopify_domain && <div className="tag-checker--bottom">
                <button onClick={handleRefresh} disabled={!refreshBtnActive}>
                    <img src={refresh_icon} alt='refresh-button'  style={{marginRight: '6px'}}/>
                    Refresh
                </button>
            </div>}
        </div>
    );
}