import api_config from '../../../../api_config.json';
const API_URL = api_config[process.env.REACT_APP_ENV].API_URL;

export const checkOrderAPIresponse = async (token, domain, shopifyAccessToken, shopifyKey) => {
    const url = `${API_URL}/shopify-api/get-orders/${domain}?accessToken=${shopifyAccessToken}&key=${shopifyKey}`
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": token
        }});
        if (!response.ok) {
            console.log(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        return {
            data: (data.checkouts && data.checkouts.length) > 0 ? data.checkouts.slice(0, 5) : {...data, status: response.status},
            isLoading: false,
            isChecked: response.ok ? true : false,
            number: data.retrievedItemCount
        }
    } catch (error) {
        throw error;
    }
}

export const checkCustomersAPIresponse = async (token, domain, shopifyAccessToken, shopifyKey) => {
    const url = `${API_URL}/shopify-api/get-customers/${domain}?accessToken=${shopifyAccessToken}&key=${shopifyKey}`
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": token
        }});
        if (!response.ok) {
            console.log(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        return {
            data: (data.customers && data.customers.length) > 0 ? data.customers.slice(0, 5) : {...data, status: response.status},
            isLoading: false,
            isChecked: response.ok ? true : false,
            number: data.retrievedItemCount
        }
    } catch (error) {
        throw error;
    }
}

export const checkProductsAPIresponse = async (token, domain, shopifyAccessToken, shopifyKey) => {
    const url = `${API_URL}/shopify-api/get-products/${domain}?accessToken=${shopifyAccessToken}&key=${shopifyKey}`
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": token
        }});
        if (!response.ok) {
            console.log(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        return {
            data: (data.products && data.products.length) > 0 ? data.products.slice(0, 5) : {...data, status: response.status},
            isLoading: false,
            isChecked: response.ok ? true : false,
            number: data.retrievedItemCount
        }
    } catch (error) {
        throw error;
    }
}