import React, { useEffect, useState, useRef } from 'react';
import './ForceRefreshDatasets.css';
import useToken from '../../../Hooks/useToken';
import Spreadsheet from "react-spreadsheet";
import api_config from '../../../../api_config.json';
import { ThreeDots } from 'react-loader-spinner';

export default function ForceRefreshDatasets(props) {
    const [data, setData] = useState([]);
    const { token } = useToken();
    const [isLoading, setIsLoading] = useState(false);
    const fileInput = useRef(); // To manage the file input for upload



    const handleRefresh = () => {
        if (window.confirm("Are you sure you want to refresh all dataset? This will take a while, so be patient.")) {
            
            setIsLoading(true);
            fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/refresh', {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(dataIn => {
               console.log(dataIn);
               setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
        }
    };
    

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'stretch', alignItems: 'center', height: '82vh'}}>
            <h1>Force Refresh Datasets</h1>
            <p>This currently does nothing (Does not Work)</p>
            {!isLoading  ?
                <button className='refresh-button' onClick={handleRefresh}>Refresh All Datasets</button>
                 :
                <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />}
        
        </div>
    );
}
