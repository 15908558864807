import React from 'react';
import close_icon from '../assets/x.svg';

export default function UserId(props){
    return (
        <div className='details-container' >
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            <div className='details-intro'>
                <p>{props.number === 10000 ? 'More than 10,000' : props.number} user IDs have been collected.</p>
                {props.data && props.data.length > 0 &&
                    <p><span style={{fontWeight: 'normal'}}>Here is a sample of {props.data.length} user IDs:</span></p>
                }
            </div>
            {props.data && props.data.length > 0 && <table>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>ID visitor</th>
                        <th># Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(e => <tr key={e.idvisitor}>
                        <td>{e.label}</td>
                        <td>{e.idvisitor}</td>
                        <td>{e.nb_actions}</td>
                    </tr>)}
                </tbody>
            </table>}
        </div>
    );
}