import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './DropdownFilters.css';

export default function DropdownFilters(props){
    return (
        <div className='dropdowns-btns'>
            <Dropdown>
                <Dropdown.Toggle variant='link' id="dropdown-custom" className='dropdown-link'>
                    Period
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-menu'>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.period === 'day' ? '#6359E9' : '#6a6791'}} onClick={() => props.setPeriod('day')}>Day</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.period === 'week' ? '#6359E9' : '#6a6791'}} onClick={() => props.setPeriod('week')}>Week</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.period === 'month' ? '#6359E9' : '#6a6791'}} onClick={() => props.setPeriod('month')}>Month</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.period === 'year' ? '#6359E9' : '#6a6791'}} onClick={() => props.setPeriod('year')}>Year</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle variant='link' id="dropdown-custom" className='dropdown-link'>
                    Date
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-menu'>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.date === 'today' ? '#6359E9' : '#6a6791'}} onClick={() => props.setDate('today')}>Today</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.date === 'yesterday' ? '#6359E9' : '#6a6791'}} onClick={() => props.setDate('yesterday')}>Yesterday</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.date === 'lastWeek' ? '#6359E9' : '#6a6791'}} onClick={() => props.setDate('lastWeek')}>Last Week</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.date === 'lastMonth' ? '#6359E9' : '#6a6791'}} onClick={() => props.setDate('lastMonth')}>Last Month</Dropdown.Item>
                    <Dropdown.Item disabled={props.isDisabled} className='dropdown-option' style={{backgroundColor: props.date === 'lastYear' ? '#6359E9' : '#6a6791'}} onClick={() => props.setDate('lastYear')}>Last Year</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}