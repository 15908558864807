import React from 'react';
import './Tags.css';
import Orders from './Orders/Orders';
import Customers from './Customers/Customers';
import Products from './Products/Products';

export default function Tags({tag, setDetails}){
    switch(tag?.tagName){
        case 'orders_response':
            return <Orders data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'customers_response':  
            return <Customers data={tag.data} number={tag.number} setDetails={setDetails} />
        case 'products_response':  
            return <Products data={tag.data} number={tag.number} setDetails={setDetails} />
        default:
            return <div></div>
    }
}