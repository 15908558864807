import React from 'react';
import './OperationDashboards.css';
import SideBar from './SideBar/SideBar';
import Dashboards from './Dashboards/Dashboards';
import useToken from '../../Hooks/useToken';
import api_config from '../../../api_config.json';
import { Outlet } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

export default function OperationDashboards(props) {
    const [dashboards, setDashboards] = React.useState(null);
    const {token} = useToken();
    const [isLoading, setIsLoading] = React.useState(false);
    const [currentDashboard, setCurrentDashboard] = React.useState(null);
    

    React.useEffect(() => {
        setIsLoading(true);
        fetch(api_config[process.env.REACT_APP_ENV].API_URL + '/quicksight/get/dashboards', {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        })
        .then(response => response.json())
        .then(dataIn => {
            setDashboards(dataIn);
            if(!currentDashboard){
                setCurrentDashboard(Object.keys(dataIn)[0])
            }
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [currentDashboard]);

    const handleChangeDashboard = (dashboardName) => {
        setCurrentDashboard(dashboardName);
    }

    return (
        <div className="ops-dashboards">
            <SideBar dashboards={dashboards} currentDashboard={currentDashboard} handleChangeDashboard={handleChangeDashboard}/>
            {currentDashboard &&
                <Dashboards link={dashboards[currentDashboard]} name={currentDashboard} />
            }
            {isLoading &&
                <ThreeDots 
                height="60" 
                width="60" 
                radius="4"
                color="#FFFFFF" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
                />
            }
        </div>
    );
}