import React from 'react';
import close_icon from '../assets/x.svg';

export default function SoldBaskets({number, data, setDetails}){
    return (
        <div className='details-container'>
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => setDetails(null)} />
            <div className='details-intro'>
                <p>{number === 10000 ? 'More than 10,000' : number} sold baskets have been collected.</p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Items in baskets</th>
                        <th>Average Basket Value</th>
                        <th>Revenu</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{data.nb_conversions}</td>
                        <td>{data.items}</td>
                        <td>{data.avg_order_revenue}</td>
                        <td>{data.revenue}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}