import React, { useEffect } from 'react';
import api_config from '../../../../api_config.json';
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

export default function Dashboard(props){

    useEffect(() => {
        console.log(props.name);
    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'stretch', alignItems: 'center', height: '82vh'}}>
            <div className='account-recs--intro'>
                <h1>{props.name}</h1>
            </div>
            <div className='account-recs'>
                    <iframe
                        src={props.link}
                        width="100%"
                        style={{borderRadius: '16px'}}
                        height="100%"
                        >
                    </iframe>
            </div>
        </div>
    );
}