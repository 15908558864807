import React from 'react';
import './GenerateProof.css';
import { ThreeDots } from 'react-loader-spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import PredefinedForm from './PredefinedForm/PredefinedForm';
import CustomForm from './CustomForm/CustomForm';
import api_config from '../../../../api_config.json';
import useToken from '../../../Hooks/useToken';

export default function GenerateProof(props) {
    const {token} = useToken();

    const [formType, setFormType] = React.useState('pre_defined'); //pre_defined or custom

    const [genLoading, setGenLoading] = React.useState(false);
    const [openLoading, setOpenLoading] = React.useState(false);

    const [proofLetter, setProofLetter] = React.useState();
    const [proofOuter, setProofOuter] = React.useState();

    const [errors, setErrors] = React.useState([]);

    const [data, setData] = React.useState();

    const API_TOKEN = api_config[process.env.REACT_APP_ENV].API_TOKEN;
    const API_URL = api_config[process.env.REACT_APP_ENV].API_URL;

    //Api call to generate proof
    const generateProof = async () => {
        setProofLetter(null);
        setProofOuter(null);
        setErrors([]);
        data.api_token_name = API_TOKEN;
        setGenLoading(true);
        const response =  await fetch(`${API_URL}/proofs/generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(data)
        });

        setGenLoading(false);

        if(response.status !== 200) {
            const error = await response.json();
            setErrors(prev => [...prev, error.message]);
            return;
        }

        const proof_data = await response.json();
        proof_data.forEach(proof => {
            if(proof.Key.startsWith('envelope')) {
                setProofOuter({key: proof.Key.replace('/', '%2F'), bucketName: proof.BucketName});
            } else {
                setProofLetter({key: proof.Key, bucketName: proof.BucketName});
            }
        });
    }

    const openProofLetter = async () => {
        setOpenLoading(true);
        const response = await fetch(
            `${API_URL}/proofs/get/${proofLetter.bucketName}/${proofLetter.key}`,
            {method: 'GET', headers: {'Authorization': token }}
        );
        setOpenLoading(false);
        if(response.status !== 200) {
            const error = await response.json();
            console.log(error);
            setErrors(prev => [...prev, error.message]);
            setProofLetter(null);
            setProofOuter(null);
            return;
        }
        const signedUrl = await response.json();
        window.open(signedUrl.signed_url, '_blank');
        setOpenLoading(false);
    }

    const openProofOuter = async () => {
        setOpenLoading(true);
        const response = await fetch(
            `${API_URL}/proofs/get/${proofOuter.bucketName}/${proofOuter.key}`,
            {method: 'GET', headers: {'Authorization': token }}
        );
        const signedUrl = await response.json();
        window.open(signedUrl.signed_url, '_blank');
        setOpenLoading(false);
    }

    return (
        <div>
            <div className="generate-proof--intro">
                <h1>Generate Proof</h1>
                <Dropdown>
                    <Dropdown.Toggle variant='link' id="dropdown-custom" className='dropdown-link'>
                        Using
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='dropdown-menu'>
                        <Dropdown.Item onClick={() => setFormType('pre_defined')} className='dropdown-option'>Predefined records</Dropdown.Item>
                        <Dropdown.Item href="" onClick={() => setFormType('custom')} className='dropdown-option'>Custom records</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="generate-proof">
                <div className="generate--form">
                    {formType === 'pre_defined' &&
                        <PredefinedForm setData={setData} />
                    }
                    {formType === 'custom' &&
                        <CustomForm setData={setData} />
                    }
                    {genLoading && <button onClick={generateProof}>
                        <div>
                            <ThreeDots 
                                height="20" 
                                width="20" 
                                radius="4"
                                color="#FFFFFF" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                            Generate Proof
                        </div>
                    </button>}
                    {!genLoading && <button onClick={generateProof}>Generate Proof</button>}
                </div>
            </div>
            {proofLetter && <div className="proof-result">
                {openLoading && <div>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="7"
                        color="#FFFFFF" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>}
                <p onClick={openProofLetter}>Open Proof Letter</p>
                {proofOuter && <p onClick={openProofOuter} className='margin-top-20'>Open Proof Outer</p>}
            </div>}
            {errors.length > 0 && <div className="errors">
                <div>
                    {errors.map((error, index) => <p key={index}>{error}</p>)}
                </div>
            </div>}
        </div>
    );
}