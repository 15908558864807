import React from 'react';
import proofs_icon from './assets/proofs-icon.svg';
import generate_icon from './assets/generate-icon.svg';
import settings_icon from './assets/settings-icon.svg';
import back_icon from './assets/back.svg'; 
import './SideBar.css';
import logo from './assets/logo.svg';
import { Link, useLocation } from 'react-router-dom';

export default function TopBar() {
    const active = {backgroundColor: '#6359E9'};
    const location = useLocation();
    const path = location.pathname;
    return (
        <div className="side-bar">
            <div className="side-bar--logo">
                <img src={logo} alt="logo" />
                <h1>Proofing</h1>
            </div>
            <div className="side-bar--menu">
                <Link className="side-bar--menu-item" to="proofs" style={path.includes('proofs') ? active : {}}>
                    <img src={proofs_icon} alt="proofs-icon" />
                    <p>Proofs</p>
                </Link>
                <Link className="side-bar--menu-item" to="generate" style={path.includes('generate') ? active : {}}>
                    <img src={generate_icon} alt="generate-icon" />
                    <p>Generate</p>
                </Link>
                <Link className="side-bar--menu-item">
                    <img src={settings_icon} alt="settings-icon" />
                    <p>Settings</p>
                </Link>
                <Link className="side-bar--menu-item back" to='/services'>
                    <img src={back_icon} alt="settings-icon" />
                    <p>Back</p>
                </Link>
            </div>
        </div>
    );
}