import React from 'react';
import close_icon from '../assets/x.svg';

export default function Orders(props){
    const formatDate = (date) => {
        const options = { day: "2-digit", month: "2-digit", year: "numeric"}
        const formattedDate = new Date(date).toLocaleDateString("en-GB", options)
        return formattedDate
    }

    return (
        <div className='details-container'>
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            {props.data && props.data.length > 0 &&
            <>
            <div className='details-intro'>
                <p>{props.number === 10000 ? 'More than 10,000' : props.number} orders have been collected.</p>
                <p><span style={{fontWeight: 'normal'}}>Here is a sample of {props.data.length} Orders:</span></p>
            </div>
                <table>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>SubTotal</th>
                            <th>Purchase Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(e => <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.subtotal_price}</td>
                            <td>{formatDate(e.created_at)}</td>
                        </tr>)}
                    </tbody>
                </table>
            </>
            }
            {!props.number && 
                <p>Status {props.data.status} - {props.data.errors}</p>
            }
        </div>
    );
}