import "./ShopifyAPIAccess.css";
import SideBar from "./SideBar/SideBar";
import { Outlet } from 'react-router-dom';

export default function ShopifyAPIAccess() {
    return (
        <div className="proofer">
            <SideBar />
            <Outlet />
        </div>
    )
}