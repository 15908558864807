import React from 'react';
import clients_icon from './assets/clients-icon.svg';
import check_icon from './assets/check-icon.svg';
import settings_icon from './assets/settings-icon.svg';
import back_icon from './assets/back.svg'; 
import './SideBar.css';
import logo from './assets/logo.svg';
import { Link, useLocation } from 'react-router-dom';

export default function TopBar() {
    const active = {backgroundColor: '#6359E9'};
    const location = useLocation();
    const path = location.pathname;
    return (
        <div className="side-bar">
            <div className="side-bar--logo">
                <img src={logo} alt="logo" />
                <h1>SFTP</h1>
            </div>
            <div className="side-bar--menu">
                <Link className="side-bar--menu-item back" to='/services'>
                    <img src={back_icon} alt="settings-icon" />
                    <p>Back</p>
                </Link>
            </div>
        </div>
    );
}