import React from 'react';
import close_icon from '../assets/x.svg';

export default function SoldProducts(props){
    return (
        <div className='details-container'>
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            <div className='details-intro'>
                <p>{props.number === 10000 ? 'More than 10,000' : props.number} sold products have been collected.</p>
                {props.data && props.data.length > 0 &&
                    <p><span style={{fontWeight: 'normal'}}>Here is a sample of {props.data.length} sold products:</span></p>
                }
            </div>
            {props.data && props.data.length > 0 &&
                <table>
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Revenu</th>
                            <th>Quantity</th>
                            <th>Average Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(e => <tr key={e.label}>
                            <td>{e.label}</td>
                            <td>{e.revenue}</td>
                            <td>{e.quantity}</td>
                            <td>{e.avg_price}</td>
                        </tr>)}
                    </tbody>
                </table>
            }
        </div>
    );
}