import React from 'react';
import './List.css';
import api_config from '../../../../api_config.json';
import useToken from '../../../Hooks/useToken';
import search_icon from './assets/search.svg';
import search_close_icon from './assets/x.svg';
import { ThreeDots } from 'react-loader-spinner';
import OptOutRecord from './OptOutRecord/OptOutRecord';
import closeIcon from './assets/x.svg';
import Swal from 'sweetalert2';

export default function List() {
    const {token} = useToken();
    // const [error, setError] = React.useState(null);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [records, setRecords] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [isMaxPage, setIsMaxPage] = React.useState(false)
    const [toBeBlacklisted, setToBeBlacklisted] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [createRecord, setCreateRecord] = React.useState(false);
    const [newRecord, setNewRecord] = React.useState({
        client_id: '',
        postcode: '',
        line1: ''
    });

    React.useEffect(() => {
        fetchRecords();
        setNewRecord(prev => ({...prev, to_be_blacklisted: toBeBlacklisted ? '1' : '0'}));
    }, [page, toBeBlacklisted, search, reload]);

    const fetchRecords = async () => {
        const API_URL = api_config[process.env.REACT_APP_ENV].OPT_OUT_API;
        let url = `${API_URL}/get-all-records?page=${page}&to_be_blacklisted=${toBeBlacklisted ? '1' : '0'}`;
        if(search && search !== ''){
            url = url + `&search=${search.replace(' ', '')}`;
        }
        try {
            setLoading(true);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const records = data['result'];
            const records_formatted = records.map(record => {
                let address = [];
                for(let key in record){
                    if(key !== 'id' && key !== 'postcode' && key !== 'client_id' && key !== 'to_be_blacklisted'){
                        address.push(record[key]);
                    }
                }
                return {
                    id: record['id'],
                    postcode: record['postcode'],
                    client_id: record['client_id'],
                    to_be_blacklisted: record['to_be_blacklisted'],
                    address: address.reverse().join(', ')
                };
            });      
            setRecords(prev => [...prev, ...records_formatted]);
            if(records_formatted.length === 0){
                setIsMaxPage(true);
            }        
            setLoading(false);
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Error!',
                text: `The following error has occurred. Please contact your administrator. Error: ${error}`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
            console.error(error);
        }
    }

    const handleSearch = (e) => {
        resetVariables();
        setSearch(e.target.value);
    }

    const handleClearSearch = () => {
        setSearch('');
        fetchRecords();
    }

    const handleLoadMore = () => {
        setPage(prev => prev + 1)
    }

    const handleToBeBlacklisted = (value) => {
        resetVariables();
        setToBeBlacklisted(value);
    }

    const resetVariables = () => {
        setRecords([]);
        setPage(1);
        setIsMaxPage(false);
        setCreateRecord(false);
        setNewRecord({
            client_id: '',
            postcode: '',
            line1: ''
        });
    }

    const updateRecord = async (record) => {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: token
            },
            body: JSON.stringify({
                'client_id': record.client_id,
                'postcode': record.postcode,
                'to_be_blacklisted': record.to_be_blacklisted
            })
        };
        const API_URL = api_config[process.env.REACT_APP_ENV].OPT_OUT_API;
        let url = `${API_URL}/update-record/${record.id}`;
        try{
            await fetch(url, options);
            resetVariables();
            setReload(prev => !prev);
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: `The following error has occurred. Please contact your administrator. Error: ${error}`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
            console.error(error);
        }
    }

    const deleteRecord = async (record) => {
        const API_URL = api_config[process.env.REACT_APP_ENV].OPT_OUT_API;
        const options = {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        }
        let url = `${API_URL}/delete-record/${record.id}`;
        try{
            await fetch(url, options);
            resetVariables();
            setReload(prev => !prev);
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: `The following error has occurred. Please contact your administrator. Error: ${error}`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
            console.error(error);
        }
    }

    const createNewRecord = async () => {
        const API_URL = api_config[process.env.REACT_APP_ENV].OPT_OUT_API;
        const options = {
            method: 'POST',
            body: JSON.stringify(newRecord),
            headers: {
                Authorization: token
            }
        };
        let url = `${API_URL}/create-record`;
        try{
            await fetch(url, options);
            resetVariables();
            setReload(prev => !prev);
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: `The following error has occurred. Please contact your administrator. Error: ${error}`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
            console.error(error);
        }
    }

    const handleCreateRecord = () => {
        console.log('yes');
        createNewRecord();
    }

    const blacklistSelection = async () => {
        records.forEach(async record => {
            record.to_be_blacklisted = '1';
            await updateRecord(record);
        })
    }

    const deleteSelection = async () => {
        records.forEach(async record => {
            await deleteRecord(record);
        })
    }

    return (
        <div className="opt-out">
            <div className="opt-out--intro">
                <div className='tbb-toggle'>
                    <button 
                        style={{borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', background: toBeBlacklisted ? '#1D1D41' : '#29294e'}}
                        onClick={() => handleToBeBlacklisted(false)}
                        disabled={!toBeBlacklisted ? true : false}
                    >  
                        Added
                    </button>
                    <button 
                        style={{borderTopRightRadius: '10px', borderBottomRightRadius: '10px', background: toBeBlacklisted ? '#29294e' : '#1D1D41'}}
                        onClick={() => handleToBeBlacklisted(true)}
                        disabled={toBeBlacklisted ? true : false}
                    >
                        To Be Blacklisted
                    </button>
                </div>
                <div className='search-group'>
                    <input type="text" className='search-input' placeholder='Search...' value={search} onChange={handleSearch} />
                    <div className='search-icon'>
                        {search !== '' ? 
                            <img src={search_close_icon} className='search_icon_close' alt='search reset' onClick={handleClearSearch} />
                        :
                            <img src={search_icon} alt='search icon' />
                        }
                    </div>
                </div>  
                <button className='add-record-btn' onClick={() => setCreateRecord(prev => !prev)}>
                    {!createRecord ? '+' :
                        <img src={closeIcon} alt='close' />
                    }
                </button>
            </div>
            {createRecord && <div className='create-record'>
                <input 
                    type='text' 
                    placeholder='Client ID' 
                    value={newRecord.client_id} 
                    style={{width: '200px'}} 
                    onChange={(e) => setNewRecord(({...newRecord, client_id: e.target.value}))} 
                />
                <input 
                    type='text' 
                    placeholder='Postcode' 
                    value={newRecord.postcode}
                    style={{width: '110px'}} 
                    onChange={(e) => setNewRecord(({...newRecord, postcode: e.target.value}))} 
                />
                <input 
                    type='text' 
                    placeholder='Address' 
                    value={newRecord.line1} 
                    style={{width: '350px'}} 
                    onChange={(e) => setNewRecord(({...newRecord, line1: e.target.value}))} 
                />
                <button onClick={handleCreateRecord}>Add record</button>
            </div>}
            <div className='opt-out-main'>
                <table className='opt-out-table'>
                    <thead>
                        <tr>
                            <th>Client ID</th>
                            <th>Postcode</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan='3'>
                            <div className='td-loading'>
                                <ThreeDots 
                                    height="40" 
                                    width="40" 
                                    radius="7"
                                    color="#FFFFFF" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                                <p>Loading...</p>
                            </div>
                        </td></tr>}
                        {!loading && records.length === 0 &&
                            <tr>
                                <td colSpan={3}>No result found.</td>
                            </tr> 
                        }
                        {
                            records.map(record => <OptOutRecord key={record.id} record={record} updateRecord={updateRecord} deleteRecord={deleteRecord} />)
                        }
                    </tbody>
                </table>
            </div>
            <div className="opt-out--bottom">
                {records && <p>{records.length} record{records.length > 1 ? 's' : ''}</p>}
                <div>
                    {!toBeBlacklisted && <button onClick={blacklistSelection}>
                        Blacklist selection
                    </button>}
                    {toBeBlacklisted && <button onClick={deleteSelection}>
                        Delete selection
                    </button>}
                    {!isMaxPage && <button onClick={handleLoadMore} style={{marginLeft: '15px', background: 'rgb(41, 41, 78)'}}>
                        Load more
                    </button>}
                </div>
            </div>
        </div>
    );
}