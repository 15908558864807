import React from 'react';
import './Proofs.css';
import api_config from '../../../../api_config.json';
import useToken from '../../../Hooks/useToken';
import Dropdown from 'react-bootstrap/Dropdown';
import search_icon from './assets/search.svg';
import search_close_icon from './assets/x.svg';
import { ThreeDots } from 'react-loader-spinner';

export default function Proofs() {
    const {token} = useToken();
    const API_URL = api_config[process.env.REACT_APP_ENV].API_URL;
    const page_size = 10;
    const [proofs, setProofs] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [sortBy, setSortBy] = React.useState('date_desc');
    const [search, setSearch] = React.useState('');
    const [hasMoreProofs, setHasMoreProofs] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        fetchProofs('1', 'date_desc', '');
    }, []);

    const fetchProofs = async (pageIn, sortByIn, searchIn, resetProofs) => {
        setLoading(true);
        fetch(`${API_URL}/proofs/getall/${page_size}?page=${pageIn}&sort_by=${sortByIn}&search=${searchIn}`,
                {method: 'GET', headers: {'Authorization': token }})
            .then(response => response.json())
            .then(data => {
                try{
                    if(Array.isArray(data.proofs)){
                        const newProofs = data.proofs;
                        console.log(data.max_page_number);
                        if(data.max_page_number === parseInt(pageIn) || data.max_page_number === 0){
                            setHasMoreProofs(false);
                        } else {
                            setHasMoreProofs(true);
                        }
                        if(resetProofs){
                            setProofs(newProofs);
                        } else {
                            setProofs(prev => [...prev, ...newProofs]);
                        }
                    } else {
                        setError('No more proofs to load.');
                    }
                    setLoading(false);
                } catch (error) {
                    setError('No more proofs to load.');
                    setLoading(false);
                }
            })
            .catch(error => {setError(error.message); setLoading(false);});
    }

    const handleSortBy = (sortByIn) => {
        setSortBy(sortByIn);
        setCurrentPage(1);
        setSearch('');
        setProofs([]);
        fetchProofs('1', sortByIn, '', true);
    }

    const handleSearch = (searchIn) => {
        setSearch(searchIn);
        setCurrentPage(1);
        setProofs([]);
        fetchProofs('1', sortBy, searchIn, true);
    }

    const handleLoadMore = () => {
        fetchProofs(currentPage + 1, sortBy, search, false);
        setCurrentPage(prev => prev + 1);
    }

    const getReference = (key) => {
        const splitKey = key.split('_');
        return splitKey[2];
    }

    const getDate = (key) => {
        const splitKey = key.split('_');
        return splitKey[0].split('-').reverse().join('-').replaceAll('-', '/');
    }

    const getTime = (key) => {
        const splitKey = key.split('_');
        return splitKey[1].replaceAll('-', ':');
    }

    const openProof = async (key, isOuter = false) => {
        //setOpenLoading(true);
        const proofKey = isOuter ? ('envelopes%2F' + key.replace('.pdf', '_outers.pdf')) : key;
        const response = await fetch(
            `${API_URL}/proofs/get/${api_config[process.env.REACT_APP_ENV].BucketName}/${proofKey}`,
            {method: 'GET', headers: {'Authorization': token }}
        );
        //setOpenLoading(false);
        if(response.status !== 200) {
            const error = await response.json();
            //setErrors(prev => [...prev, error.message]);
            return;
        }
        const signedUrl = await response.json();
        window.open(signedUrl.signed_url, '_blank');
        //setOpenLoading(false);
    }

    return (
        <div className="proofs">
            <div className="generate-proof--intro">
                <h1>Proofs</h1>  
                <div className='search-group'>
                    <input type="text" className='search-input' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} value={search} />
                    <div className='search-icon'>
                        {search !== '' ? 
                            <img src={search_close_icon} className='search_icon_close' alt='search reset' onClick={(e) => handleSearch('')}/>
                        :
                            <img src={search_icon} alt='search icon' />
                        }
                    </div>
                </div>  
                
                <Dropdown>
                    <Dropdown.Toggle variant='link' id="dropdown-custom" className='dropdown-link'>
                        Sort by
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdown-menu'>
                        <Dropdown.Item onClick={() => handleSortBy('date_asc')} className='dropdown-option'>Date ASC</Dropdown.Item>
                        <Dropdown.Item href="" onClick={() => handleSortBy('date_desc')} className='dropdown-option'>Date DESC</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='proofs-main'>
                <table className='proofs-table'>
                    <thead>
                        <tr>
                            <th>Proof Reference</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Open</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proofs.map(proof => {
                            return (<tr key={proof.lastModified}>
                                        <td>{getReference(proof.key)}</td>
                                        <td>{getDate(proof.key)}</td>
                                        <td>{getTime(proof.key)}</td>
                                        <td className='td-open'>
                                            <button className='open-letter-btn' onClick={() => openProof(proof.key)}>Letter</button>
                                            <button className='open-outer-btn' onClick={() => openProof(proof.key, true)}>Outer</button>
                                        </td>
                                    </tr>)
                        })}
                        {!loading && proofs.length === 0 && <tr><td colSpan='4'>No proofs found.</td></tr>}
                        {loading && <tr><td colSpan='4'>
                            <div className='td-loading'>
                                <ThreeDots 
                                    height="40" 
                                    width="40" 
                                    radius="7"
                                    color="#FFFFFF" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                                <p>Loading...</p>
                            </div>
                        </td></tr>}
                    </tbody>
                </table>
                {error &&
                    <div className="error">
                        <p>{error}</p> 
                    </div>
                }
            </div>
            {hasMoreProofs &&
                <div className='load-more-group'>
                    <button className='load-more-button' onClick={() => handleLoadMore(prev => prev + 1)}>Load more proofs</button>
                </div>
            }
        </div>
    );
}