import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { PersonCircle } from 'react-bootstrap-icons';

export default function Navigation() {

    const logout = () => {
        sessionStorage.removeItem('token');
        window.location.reload();
    }

    return (
        <Navbar bg="dark" variant="dark">
        <Container>
            <Navbar.Brand href="/">Paperplanes Services</Navbar.Brand>
            <Nav className="me-auto">
                {/* <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/services">Services</Nav.Link>
                <Nav.Link href="/about">About</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link> */}
            </Nav>
            {/* Dropdow button display profile, settings and logout */}
            <Nav className="mr-auto">
                <NavDropdown title={<PersonCircle />} id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.2">My profile</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Settings</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Container>
        </Navbar>
    );
}