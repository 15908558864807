import React from 'react';
import './Check.css';
import "bootstrap/dist/css/bootstrap.min.css";
import useToken from '../../../Hooks/useToken';
import DropdownFilters from './DropdownFilters/DropdownFilters';
import Tag from './Tag/Tag';
import Tags from './Tags/Tags';
import {
    checkAbandonedBaskets, 
    checkSoldBaskets, 
    checkAbandonedProducts, 
    checkSoldProducts, 
    checkUserIds, 
    checkNamesAddresses
} from './getData'
import refresh_icon from './assets/arrow-clockwise.svg';
import Swal from 'sweetalert2';
import { useParams, Link } from 'react-router-dom';

export default function Check() {
    const {token} = useToken();
    const { id, ref } = useParams();
    const [client] = React.useState({
        'client_ref': ref ? ref : null,
        'idSite': id ? id : null
    });
    
    const [period, setPeriod] = React.useState('day');
    const [date, setDate] = React.useState('yesterday');
    const [details, setDetails] = React.useState(null)
    const [tags, setTags] = React.useState([]);  
    const [refresh, setRefresh] = React.useState(false);
    const [refreshBtnActive, setRefreshBtnActive] = React.useState(false);

    React.useEffect(() => {
        console.log(client.client_ref, client.idSite)
        async function checkTags(){
            setRefreshBtnActive(false);
            const abandonedBaskets = await checkAbandonedBaskets(token, client['idSite'], date, period);
            setTags(prev => ({...prev, abandoned_baskets: abandonedBaskets}));
            const soldBaskets = await checkSoldBaskets(token, client['idSite'], date, period);
            setTags(prev => ({...prev, sold_baskets: soldBaskets}));
            const abandonedProducts = await checkAbandonedProducts(token, client['idSite'], date, period); 
            setTags(prev => ({...prev, abandoned_products: abandonedProducts}));
            const soldProducts = await checkSoldProducts(token, client['idSite'], date, period); 
            setTags(prev => ({...prev, sold_products: soldProducts}));
            const userIds = await checkUserIds(token, client['idSite'], date, period);
            setTags(prev => ({...prev, user_ids: userIds}));
            const namesAddresses = await checkNamesAddresses(token, client['idSite'], date, period); 
            setTags(prev => ({...prev, names_addresses: namesAddresses}));
            setRefreshBtnActive(true);
        }
        checkTags().catch(error => {
            Swal.fire({
              title: 'Error!',
              text: `The following error has occurred. Please contact your administrator. Error: ${error}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
            console.error(error);
        });
        
    }, [token, client, refresh, date, period])

    const handleRefresh = () => {
        setTags([]);
        setDetails(null);
        setRefresh(prev => !prev);
    }

    const handleSetDetails = (tag) => {
        if(tag === null || Object.keys(tags).includes(tag.tagName)){
            setDetails(tag);
        }
    }

    const handleSetDate = (date) => {
        setDate(date);
        handleRefresh();
    }

    const handleSetPeriod = (period) => {
        setPeriod(period);
        handleRefresh();
    }

    return (
        <div>
            <div className="tag-checker--intro">
                {client.client_ref ?
                    <h1>Check Tags - {client.client_ref}</h1>
                :
                    <h1>Check Tags</h1>
                }
                <DropdownFilters period={period} date={date} setDate={handleSetDate} setPeriod={handleSetPeriod} isDisabled={!refreshBtnActive} />
            </div>
            <div className="tag-checker">
                {!client.idSite && 
                    <div className='initial-button-container'>
                        <Link to='/tag-checker/clients'><button>Select a Client</button></Link>
                    </div>
                }
                {client.idSite && <div className='tag-section'>
                    <div className='tags-list'>
                        {!(date === 'today' && period === 'day') &&
                            <Tag tag={tags.abandoned_baskets} label='Collecting Abandoned Baskets' tagName='abandoned_baskets' setDetails={handleSetDetails} />
                        }
                        {!(date === 'today' && period === 'day') &&
                            <Tag tag={tags.sold_baskets} label='Collecting Sold Baskets' tagName='sold_baskets' setDetails={handleSetDetails} />
                        }
                        <Tag tag={tags.abandoned_products} label='Collecting Abandoned Products' tagName='abandoned_products' setDetails={handleSetDetails} />
                        <Tag tag={tags.sold_products} label='Collecting Sold Products' tagName='sold_products' setDetails={handleSetDetails} />
                        <Tag tag={tags.user_ids} label='Collecting User IDs' tagName='user_ids' setDetails={handleSetDetails} />
                        <Tag tag={tags.names_addresses} label='Collecting Names and Addresses' tagName='names_addresses' setDetails={handleSetDetails} />
                    </div>
                    <Tags tag={details} setDetails={handleSetDetails} />
                </div>}
            </div>
            {client.idSite && <div className="tag-checker--bottom">
                <button onClick={handleRefresh} disabled={!refreshBtnActive}>
                    <img src={refresh_icon} alt='refresh-button'  style={{marginRight: '6px'}}/>
                    Refresh
                </button>
            </div>}
        </div>
    );
}