import React from 'react';
import './Login.css';
import {CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'
import api_config from '../../api_config.json';
import jwtDecode from 'jwt-decode';

export default function Login(props) {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [error, setError] = React.useState(null);


    const handleLogin = async (e) => {
        e.preventDefault();
        const userPool = new CognitoUserPool({
            UserPoolId: api_config[process.env.REACT_APP_ENV].UserPoolId,
            ClientId: api_config[process.env.REACT_APP_ENV].ClientId
        })
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });
        
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });
        
        setError(null);

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                const userInfo  = jwtDecode(result.idToken.jwtToken);
                props.setUser({email: userInfo.email, groups: userInfo['cognito:groups'] ? userInfo['cognito:groups'] : []});
                props.setToken(result.idToken.jwtToken);
            },
            onFailure: (err) => {
                setError(err.message);
            },
        });
    }


    return (
        <div className="login">
            <div className="login--form">
                <h1>Sign in</h1>
                <p>Paperplanes' System</p>
                <div className='login--form-inputs'>
                    <form onSubmit={handleLogin} className='from--login'>
                        <input type="text" required placeholder="Email Address" onChange={(e) => setUsername(e.target.value)} value={username}/>
                        <input type="password" required placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                        {/* <button onClick={(e) => window.location.assign('/proofs')}>Login</button> */}
                        <button type='submit'>Login</button>
                    </form>
                    {error && <div className='error'>
                        <p>{error}</p>
                    </div>}
                </div>
            </div>
        </div>
    );
}