import React from 'react';
import './PredefinedForm.css';

export default function PredefinedForm(props) {
    const [formData, setFormData] = React.useState({
        client_reference: '',
        template_name: '',
        outer_pdf_template: '',
        output_format: '',
        Campaign: 'ABD',
        ABGroup: 'A',
        numberOfRecords: 10,
        product_url1: "",
        product_url2: "",
        product_url3: "",
        product_url4: ""
    });

    const [advancedOptions, setAdvancedOptions] = React.useState(false);

    const handleAdvancedOptions = () => {
        setAdvancedOptions(prev => !prev);
    }

    const handleFormData = (e) => {
        const {id, value} = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    }

    const {setData} = props;

    React.useEffect(() => {
        setData({
            type: "pre_defined",
            parameters: {
                "ClientReference": formData.client_reference,
                "TemplateName": formData.template_name,
                "OuterPdfTemplate": formData.outer_pdf_template,
                "OutputFormat": formData.output_format
            },
            recordValues: {
                "Campaign": formData.Campaign,
                "ABGroup": formData.ABGroup
            },
            optional:{
                "numberOfRecords": formData.numberOfRecords,
                productImages: {
                    "product_url1": formData.product_url1,
                    "product_url2": formData.product_url2,
                    "product_url3": formData.product_url3,
                    "product_url4": formData.product_url4
                }
            },
        });
    }, [setData, formData]);

    return (
        <div className='predefined-form'>
            <div className='form-group'>
                <label htmlFor="client_reference">Client Reference</label>
                <input type="text" id='client_reference' placeholder="E.g. Mattressman" onChange={handleFormData} value={formData.client_reference}/>
            </div>
            <div className='form-group'>
                <label htmlFor="template_name">Template Name</label>
                <input type="text" id='template_name' placeholder="E.g. AbandonedBasket" onChange={handleFormData} value={formData.template_name}/>
            </div>
            <div className='form-group'>
                <label htmlFor="outer_pdf_template">Outer PDF Template</label>
                <input type="text" id='outer_pdf_template' placeholder="E.g. Mattressman/Outer" onChange={handleFormData} value={formData.outer_pdf_template}/>
            </div>
            <div className='form-group'>
                <label htmlFor="output_format">Output Format</label>
                <input type="text" id='output_format' placeholder="E.g. A4" onChange={handleFormData} value={formData.output_format}/>
            </div>
            <div className='advanced-options-label' style={{marginBottom: advancedOptions ? '30px' : '0'}}>
                <input type="checkbox" id='advanced_options' onClick={handleAdvancedOptions}/>
                <label htmlFor='advanced_options'>Advanced Options</label>
            </div>
            {advancedOptions && <div className='advanced-options-form'>
                <div className='form-group'>
                    <label htmlFor="Campaign">User Campaign</label>
                    <input type="text" id='Campaign' value={formData.Campaign} onChange={handleFormData}/>
                </div>
                <div className='form-group'>
                    <label htmlFor="ABGroup">ABGroup</label>
                    <input type="text" id='ABGroup' value={formData.ABGroup} onChange={handleFormData}/>
                </div>
                <div className='form-group'>
                    <label htmlFor="numberOfRecords">Number of records</label>
                    <input type="number" id='numberOfRecords' value={formData.numberOfRecords} onChange={handleFormData}/>
                </div>
                <div className='form-group'>
                    <label htmlFor="product_url1">Product URL 1</label>
                    <input type="text" id='product_url1' value={formData.product_url1} onChange={handleFormData}/>
                </div>
                <div className='form-group'>
                    <label htmlFor="product_url2">Product URL 2</label>
                    <input type="text" id='product_url2' value={formData.product_url2} onChange={handleFormData}/>
                </div>
                <div className='form-group'>
                    <label htmlFor="product_url3">Product URL 3</label>
                    <input type="text" id='product_url3' value={formData.product_url3} onChange={handleFormData}/>
                </div>
                <div className='form-group'>
                    <label htmlFor="product_url4">Product URL 4</label>
                    <input type="text" id='product_url4' value={formData.product_url4} onChange={handleFormData}/>
                </div>
            </div>}
        </div>
    );
}