import React from 'react';
import './Tag.css';
import { ThreeCircles } from 'react-loader-spinner';
import check_mark from './assets/check-circle-fill.svg';
import fail_mark from './assets/x-circle-fill.svg';
import arrow_right from './assets/arrow-right.svg';

export default function Tag({tagName, tag, setDetails, label}){
    const [tagDetail, setTagDetail] = React.useState(null);
    const [displayArrow, setDisplayArrow] = React.useState(false);

    React.useEffect(() => {
        setTagDetail({
            tagName: tagName,
            number: tag?.number,
            data: tag?.data
        })
    }, [tag, tagName])
    return (
        <div className='check-tag' onClick={() => setDetails(tagDetail)}  onMouseOver={() => setDisplayArrow(true)} onMouseOut={() => setDisplayArrow(false)}>
            {!tag || tag.isLoading ?
                <ThreeCircles
                    height="20"
                    width="20"
                    radius={1}
                    color="#6359E9"
                />
            :
                <img src={tag.isChecked ? check_mark : fail_mark} alt='check_mark' />
            }
            <p>{label}</p>
            <img src={arrow_right} alt='arrow-right' className='tag-arrow-right' style={{display: displayArrow ? 'block' : 'none'}} />
        </div>
    );
}