import React from 'react';

export default function CustomForm(props){
    const [inputData, setInputData] = React.useState();

    const {setData} = props;

    React.useEffect(() => {
        setData({
            type: "custom",
            input: inputData
        });
        console.log("inputData", inputData);
    }, [setData, inputData]);

    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setInputData(e.target.result);
        };
    };

    return (
        <div className='form-group'>
            <label htmlFor="inputJson">Choose an input JSON file</label>
            <input id='inputJson' type="file" onChange={handleChange} />
        </div>
    );
}