import React from 'react';
import './Service.css';
import {Link} from 'react-router-dom';

export default function Service(props) {

    return (
        <Link className='service' to={props.link}>
            <img src={props.img} alt="" />
            <p>{props.title}</p>
        </Link>
    );
}