import React from 'react';
import './Clients.css';
import api_config from '../../../../api_config.json';
import useToken from '../../../Hooks/useToken';
import Dropdown from 'react-bootstrap/Dropdown';
import search_icon from './assets/search.svg';
import search_close_icon from './assets/x.svg';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

export default function Clients() {
    const {token} = useToken();
    const [matomoToken] = React.useState('deea8de1310786db186068ce614e38ea');
    const [error, setError] = React.useState(null);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [clients, setClients] = React.useState([]);
    // const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async (filter = null) => {
        const API_URL = api_config[process.env.REACT_APP_ENV].API_URL;
        const url = `${API_URL}/tag-checker/sites/get-all?authToken=MATOMO_AUTH_TOKEN`;
        try {
            setLoading(true);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
            }});
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if(filter){
                setClients(data.filter(client => client['name'].toLowerCase().includes(filter.toLowerCase()) || client['idsite'].includes(filter)))
            } else {
                setClients(data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
            throw error;
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
        fetchClients(e.target.value);
    }

    const handleClearSearch = () => {
        setSearch('');
        fetchClients();
    }

    return (
        <div className="proofs">
            <div className="generate-proof--intro">
                <h1>Clients</h1>  
                <div className='search-group'>
                    <input type="text" className='search-input' placeholder='Search...' value={search} onChange={handleSearch} />
                    <div className='search-icon'>
                        {search !== '' ? 
                            <img src={search_close_icon} className='search_icon_close' alt='search reset' onClick={handleClearSearch} />
                        :
                            <img src={search_icon} alt='search icon' />
                        }
                    </div>
                </div>  
                
                <Dropdown aria-disabled={true}>
                    <Dropdown.Toggle variant='link' id="dropdown-custom" className='dropdown-link' disabled>
                        Sort by
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdown-menu'>
                        <Dropdown.Item className='dropdown-option'>Date ASC</Dropdown.Item>
                        <Dropdown.Item href="" className='dropdown-option'>Date DESC</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='proofs-main'>
                <table className='proofs-table'>
                    <thead>
                        <tr>
                            <th>Client Name</th>
                            <th>URL</th>
                            <th>ID Site</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan='3'>
                            <div className='td-loading'>
                                <ThreeDots 
                                    height="40" 
                                    width="40" 
                                    radius="7"
                                    color="#FFFFFF" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                                <p>Loading...</p>
                            </div>
                        </td></tr>}
                        {!loading && clients.length === 0 &&
                            <tr>
                                <td colSpan={3}>No result found.</td>
                            </tr> 
                        }
                        {
                            clients.map(client => { return(
                                <tr onClick={() => {window.location.href = `/tag-checker/check/${client.idsite}/${client.name}`;}} key={client.idsite}>
                                    <td>{client.name}</td>
                                    <td>{client.main_url}</td>
                                    <td>{client.idsite}</td>
                                </tr>)})
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}