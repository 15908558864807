import React from 'react';
import close_icon from '../assets/x.svg';
import shortid from 'shortid';

export default function NamesAddresses(props){  

    return (
        <div className='details-container'>
           <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            <div className='details-intro'>
                <p>{props.number === 10000 ? 'More than 10,000' : `Around ${props.number}`} names and addresses have been collected.</p>
                {props.data && props.data.filter(e => e.length > 0).length > 0 &&
                    <p><span style={{fontWeight: 'normal'}}>Here is a example of {props.data.filter(e => e.length > 0).length} names & addresses (data had been randomly mixed to guarantee anonymity):</span></p>
                }
            </div>
            {props.data && props.data.filter(e => e.length > 0).length > 0 && <table>
                <thead>
                    <tr>
                        <th colSpan={props.data[0].length}>Names and addresses</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(e => <tr key={shortid.generate()}>
                        {e.map(f => <td key={f}>{`${f} `}</td>)}
                    </tr>)}
                </tbody>
            </table>}
        </div>
    );
}