import React from 'react';
import './OptOutRecord.css';
import penIcon from './assets/pen.svg';
import cancelIcon from './assets/cancel.svg';
import submitIcon from './assets/submit.svg';
import settingIcon from './assets/gear-fill.svg';
import Swal from 'sweetalert2';

export default function OptOutRecord(props){
    const [record] = React.useState(props.record);
    const [expand, setExpand] = React.useState(false);
    const [displayPen, setDisplayPen] = React.useState(false); 
    const [displaySubmit, setDisplaySubmit] = React.useState(false);
    const [displayCancel, setDisplayCancel] = React.useState(false);
    const [clientId, setClientId] = React.useState(props.record.client_id);
    const [postcode, setPostode] = React.useState(props.record.postcode);
    const [displaySettings, setDisplaySettings] = React.useState(false);
    const [displayBgIcons, setDisplBgIcons] = React.useState(false);
    React.useEffect(() => {
        if(expand){
            setDisplayCancel(true);
            setDisplaySubmit(true);
            setDisplayPen(false);
            setDisplaySettings(true);
        } else {
            setDisplayCancel(false);
            setDisplaySubmit(false);
            setDisplaySettings(false);
        }
    }, [expand])
    
    const handleClick = () => {
        if(!expand){
            setExpand(prev => !prev);
        }
    }

    const handleOnMouseEnter = () => {
        if(!expand){
            setDisplayPen(true)
        };
        setDisplBgIcons(true);
    }

    const handleOnMouseLeave = () => {
        setDisplayPen(false);
        setDisplBgIcons(false);
    }

    const handleSubmit = () => {
        record.client_id = clientId;
        record.postcode = postcode;
        setExpand(false);
        props.updateRecord(record);
    }

    const handleClientIdChange = (e) => {
        setClientId(e.target.value)
    }

    const handlePostcodeChange = (e) => {
        setPostode(e.target.value)
    }

    const handleSettings = () => {
        console.log(record);
        const showConfirmButton = record.to_be_blacklisted === '1' ? false : true;
        Swal.fire({
            title: 'Options',
            icon: 'info',
            confirmButtonText: 'Blacklist',
            showConfirmButton: showConfirmButton,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: 'Delete record'
        }).then( result => {
            if(result.isConfirmed){
                record.to_be_blacklisted = '1';
                props.updateRecord(record);
                Swal.close();
            } else if (result.isDenied){
                props.deleteRecord(record);
                Swal.close();
            }
        });
    } 

    return (
        <tr 
            onClick={handleClick} 
            className='opt-out-record'
            onMouseOver={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            >
                <td>{!expand ? 
                    record.client_id :
                    <input type='text' value={clientId} onChange={handleClientIdChange}/>
                }</td>  
                <td>{!expand ?
                    record.postcode :
                    <input type='text' value={postcode} style={{width: '100px'}} onChange={handlePostcodeChange} />
                }</td>
                <td>{record.address}</td>
                <div className='icons' style={{background: displayBgIcons ? '#29294e' : '#1D1D41'}}>
                    <img src={penIcon} alt='edit' style={{display: displayPen ? 'block' : 'none'}}/>
                    <img src={settingIcon} alt='cancel' style={{display: displaySettings ? 'block' : 'none'}} onClick={handleSettings}/>
                    <img src={cancelIcon} alt='cancel' style={{display: displayCancel ? 'block' : 'none'}} onClick={() => setExpand(false)}/>
                    <img src={submitIcon} alt='submit' style={{display: displaySubmit ? 'block' : 'none'}} onClick={handleSubmit}/>
                </div>
        </tr>
    )
}