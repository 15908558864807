import React from 'react';
import close_icon from '../assets/x.svg';

export default function Customers(props){
    const currencyCodeToSymbol = (currencyCode) => {
        try {
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
          });
      
          // Extracting the currency symbol from the formatted string
          const parts = formatter.formatToParts(1);
          const symbol = parts.find((part) => part.type === 'currency').value;
      
          return symbol || currencyCode;
        } catch (error) {
          console.error('Error converting currency code to symbol:', error.message);
          return currencyCode;
        }
    };
    return (
        <div className='details-container'>
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            {props.data && props.data.length > 0 && 
            <>
            <div className='details-intro'>
                <p>{props.number === 10000 ? 'More than 10,000' : props.number} customers have been collected.</p>
                <p><span style={{fontWeight: 'normal'}}>Here is a sample of {props.data.length} customers:</span></p>
            </div>
                <table>
                    <thead>
                        <tr>
                            <th>Customer ID</th>
                            <th>City - Country</th>
                            <th>State</th>
                            <th>Lifetime Spend</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(e => <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.default_address?.city} - {e.default_address?.country_code}</td>
                            <td>{e.tags}</td>
                            <td>{currencyCodeToSymbol(e.currency)}{e.total_spent}</td>
                        </tr>)}
                    </tbody>
                </table>
            </>
            }
            {!props.number && 
                <p>Status {props.data.status} - {props.data.errors}</p>
            }
        </div>
    );
}