import React from 'react';
import close_icon from '../assets/x.svg';

export default function AbandonedBaskets(props){
    return (
        <div className='details-container'>  
            <img src={close_icon} alt='close-tab' className='close-icon' onClick={() => props.setDetails(null)} />
            <div className='details-intro'>
                <p>{props.number === 10000 ? 'More than 10,000' : props.number} abandoned baskets have been collected.</p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Items in baskets</th>
                        <th>Average Basket Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.data.nb_conversions}</td>
                        <td>{props.data.items}</td>
                        <td>{props.data.avg_order_revenue}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}